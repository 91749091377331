import axios from 'axios';

export const API_BASE_URL = 'https://www.hspace.top/qa-system';
// export const API_BASE_URL = 'http://localhost:8100/qa-system';

const client = axios.create({
  baseURL: API_BASE_URL,
});

// 响应拦截器
client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // 你可以在这里添加全局错误处理逻辑
    console.error('API call failed:', error);
    return Promise.reject(error);
  }
);

const apiService = {
  get: (url) => client.get(url),
  post: (url, data) => client.post(url, data),
  put: (url, data) => client.put(url, data),
  delete: (url) => client.delete(url),
};


export default apiService;