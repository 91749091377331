import React, { useState, useEffect, useRef } from 'react';
import './styles/Chat.scss';
import Dialog from '../../components/modals/dialog/dialog';
import BotModal from '../../components/modals/botmodal/botmodal';
import { X, ZoomIn, ZoomOut, Trash2Icon, Edit2Icon } from 'lucide-react';
import Admin from './admin/admin';
import Chat from './chat/chat'
import api from '../../api'
import { message } from 'antd';
import { API_BASE_URL } from '../../api';
import tool from '../../utils';
import logoIcon from '../../assets/logo.png';
import bannerIcon from '../../assets/banner.png';

const SSE_URL = `${API_BASE_URL}/llm/chat`;

const agents = [
    // { id: 1, name: "客服小美", avatar: "https://i.pravatar.cc/100?img=1" },
];

const ChatPage = () => {
    const [agentList, setAgentList] = useState(agents || []);
    const [currentAgent, setCurrentAgent] = useState({});
    const [chatHistory, setChatHistory] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewScale, setPreviewScale] = useState(1);
    const chatWindowRef = useRef(null);
    const textareaRef = useRef(null);
    const fileInputRef = useRef(null);
    const [showMode, setShowMode] = useState('chat')
    const inputTimer = useRef(null)
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const eventSourceRef = useRef(null);
    const lock = useRef(false);
    const deleteBotId = useRef(null);
    const [editAgent, setEditAgent] = useState(null);
    const [showBotModal, setShowBotModal] = useState(false);
    const [closing, setClosing] = useState(false);
    const [botModalType, setBotModalType] = useState('add');
    const [searchInput, setSearchInput] = useState('');
    const agent_list = useRef([]);

    useEffect(() => {
        handleGetBots();
        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if(!currentAgent) return;
        fetchChatHistory(currentAgent?.id);
    }, [currentAgent]);

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [chatHistory]);
    const handleGetBots = async () => {
        try {
            const { code, data, msg } = await api.get('/bot/bots')
            if(code !== 2000) return message.error(msg);
            setAgentList(Array.isArray(data.list) ? data.list : []);
            agent_list.current = data.list;
            setCurrentAgent(data.list[0]);
        } catch (error) {
            message.error(error)
            setAgentList([]); // 出错时设置为空数组
        }
    }
    const cleanText = (text) => {
        // 移除所有空白字符（包括空格、制表符、换行符等）
        const cleaned = text.replace(/\s+/g, '');
        return cleaned;
      };
    
      const removeLineBreaks = (text) => {
        // 只移除换行符，保留其他空白字符
        const cleaned = text.replace(/[\r\n]+/g, '');
        return cleaned;
      };

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);
    const handleConfirmDeleteBot = async () => {
        console.log('Confirmed');
        const { code, msg } = await api.post('/bot/delete_bot', {agentId: deleteBotId.current})
        if(code!==2000) return message.error(msg);
        const index = agentList.findIndex(item=>item.id===deleteBotId.current)
        setCurrentAgent(index===agentList.length-1? agents[0] : agentList[index + 1])
        const newList = agentList.filter(item=>item.id!==deleteBotId.current)
        setAgentList(newList);
        message.success(msg);
        setIsDialogOpen(false);
        deleteBotId.current = null;
    };

    const fetchChatHistory = async (agentId) => {
        try {
            const fakeChatHistory = [
                { default: true, text: "您好，我有一个问题想咨询。", sender: 'user' },
                { default: true, text: "好的，请问您有什么需要帮助的吗？", sender: 'ai' },
            ];
            const { code, data, msg } = await api.post('/bot/chat_records', {current: 1, pageSize: 20, userId: 'xiaoyang', agentId: agentId})
            if(code !== 2000) return setChatHistory(fakeChatHistory);
            const list = data.list.flatMap(item => [
                {text: item.question, sender: 'user', time: item.create_time}, 
                {text: item.answer, sender: 'ai', time: item.create_time}
            ]);
            fakeChatHistory.push(...list);
            setChatHistory(fakeChatHistory);
        } catch (error) {
            console.error(error?.response?.data.msg);
            // message.error('获取聊天记录失败');
        }
    };

    const selectAgent = (agent) => {
        setCurrentAgent(agent);
        setChatHistory([]);
    };

    const addMessage = (text, sender) => {
        setChatHistory(prev => [...prev, { text, sender }]);
    };

    const saveRecord = async ({userId, agentId, question, answer, msgType})=> {
        try {
            const { code, data, msg } = await api.post('/bot/save_chat_record', {userId, agentId, question, answer, msgType})
            if(code !== 2000) {
                message.error(msg);
                return false
            }
            return data
        } catch (error) {
            message.error('保存失败')
        }

    }

    const sendMessage = async () => {
        if (lock.current) return;
        lock.current = true;
        if (inputMessage.trim() && currentAgent) {
            const currentMessage = inputMessage;
            setInputMessage(''); // 清空输入框
            setIsLoading(true);
            
            try {
                const historyList = tool.convertChatToQA(chatHistory);
                const currentTime = new Date().toISOString(); // 获取当前时间
                // 添加用户的消息到聊天历史，包含时间戳
                setChatHistory(prevHistory => [...prevHistory, { text: currentMessage, sender: 'user', time: currentTime }]);
    
                // 创建新的 EventSource 连接
                if (eventSourceRef.current) {
                    eventSourceRef.current.close();
                }
                eventSourceRef.current = new EventSource(`${SSE_URL}?question=${encodeURIComponent(currentMessage)}&history=${encodeURIComponent(JSON.stringify(historyList))}&kb_id=${currentAgent?.kb_id}`);
    
                let aiResponse = '';
                let aiResponseTime = new Date().toISOString(); // AI 回复的时间戳
    
                eventSourceRef.current.onmessage = async (event) => {
                    if (event.data === '[end]') {
                        console.log('Final AI response:', aiResponse);
                        lock.current = false;
                        setIsLoading(false);
                        const recordId = await saveRecord({
                            userId: 'xiaoyang',
                            agentId: currentAgent?.id,
                            question: currentMessage,
                            answer: aiResponse,
                            msgType: 'text'
                        });
                        if (!recordId) return;
                        eventSourceRef.current.close();
                    } else {
                        aiResponse += event.data;
                        setChatHistory(prevHistory => {
                            const updatedHistory = [...prevHistory];
                            if (updatedHistory[updatedHistory.length - 1].sender === 'ai') {
                                updatedHistory[updatedHistory.length - 1] = { ...updatedHistory[updatedHistory.length - 1], text: aiResponse, time: aiResponseTime };
                            } else {
                                updatedHistory.push({ text: aiResponse, sender: 'ai', time: aiResponseTime });
                            }
                            return updatedHistory;
                        });
                    }
                };
    
                eventSourceRef.current.onerror = (error) => {
                    console.error('EventSource failed:', error);
                    eventSourceRef.current.close();
                    setIsLoading(false);
                    lock.current = false;
                };
    
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
                setInputMessage(currentMessage);
                lock.current = false;
                message.error('发送消息失败，请重试' + error);
            }
        }
    };



    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
        adjustTextareaHeight();
    };

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 300)}px`;
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // 这里处理文件上传逻辑
            console.log('File selected:', file.name);
        }
    };

    const openFileDialog = () => {
        fileInputRef.current.click();
    };

    const openImagePreview = (imageUrl) => {
        setPreviewImage(imageUrl);
        setPreviewScale(1);
    };

    const handleZoom = (delta) => {
        setPreviewScale(prevScale => Math.max(0.1, Math.min(3, prevScale + delta)));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const handleConfirmAddBot = async (params) => {
        const { code, data, msg } = await api.post('/bot/add_bot', {...params});
        if(code !== 2000) return message.error(msg);
        message.success(msg);
        setAgentList(list => [{...params, id: data.id, kb_id: data.kb_id}, ...list]);  // 使用展开运算符创建新数组
        setCurrentAgent({...params, ...data})
        handleCloseBotModal();
    }

    const handleConfirmUpdateBot = async (params)=> {
        const { code, data, msg } = await api.post('/bot/update_bot', {...params, agentId: editAgent.id})
        if(code!==2000) return message.error(msg);
        message.success(msg);
        const index = agentList.findIndex(item=>item.id===editAgent.id)
        agentList[index].avatar = data.avatar
        agentList[index].name = data.name
        // agentList[index].desc = data.desc
        setAgentList(agentList)
        setEditAgent(null)
        handleCloseBotModal()
    }

    const handleCloseBotModal = ()=> {
        setClosing(true)
        setTimeout(() => {
            setClosing(false)
            setShowBotModal(false)
        }, 300);
    }

    const handleConfirmSearch = ()=>{
        inputTimer.current && clearTimeout(inputTimer.current)
        inputTimer.current = setTimeout(() => {
            setAgentList(list => list.filter(item=> item.name.includes(searchInput)))
        }, 500)
    }

    return (
        <div className="chat-container">
            <div className="sidebar">
                <div className="sidebar-header">
                    <div className='flex'>
                        <img className='banner' src={bannerIcon} alt='' />
                        {/* <img className='icon' src={logoIcon} alt='logo' /> */}
                         {/* AI客服中心 */}
                    </div>
                    <div className='flex add' onClick={()=>{
                        setBotModalType('add')
                        setEditAgent(null)
                        setShowBotModal(true)
                    }}>
                        <span>+</span>
                    </div>
                </div>
                <div className='search-input'>
                    <div className='input-box'>
                        <input 
                            className='input' 
                            placeholder='输入客服名称' 
                            onKeyUp={(e)=>{
                                if (e.key === 'Enter') {
                                    handleConfirmSearch()
                                }
                            }}
                            onChange={(e) => {
                                const value = e.target.value
                                setSearchInput(value)
                                if(value==='') setAgentList(agent_list.current)
                            }}
                        />
                    </div>
                </div>
                <ul className="agent-list">
                    {Array.isArray(agentList) && agentList.map(agent => (
                        <li
                            key={agent?.id}
                            className={`agent-item ${currentAgent?.id === agent.id ? 'active' : ''}`}
                            onClick={() => {
                                if (currentAgent?.id === agent.id) return;
                                selectAgent(agent)
                            }}
                        >
                            <div className="agent-avatar">
                                <img src={agent.avatar} alt='' />
                            </div>
                            <div className='flex-normal'>
                                <span className='name'>{agent.name}</span>
                                <div className='float-box'>
                                    <div className='float-light' onClick={e=>{
                                        e.stopPropagation()
                                        deleteBotId.current = agent.id
                                        handleOpenDialog()
                                    }}>
                                        <Trash2Icon size={14} />
                                    </div>
                                    <div className='float-light' onClick={e=>{
                                        e.stopPropagation()
                                        setEditAgent(agent)
                                        setShowBotModal(true)
                                        setBotModalType('update')
                                    }}>
                                        <Edit2Icon size={14} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            {showMode === 'chat' ?
                <Chat 
                    openFileDialog={openFileDialog}
                    currentAgent={currentAgent} 
                    openImagePreview={openImagePreview} 
                    chatWindowRef={chatWindowRef} 
                    isLoading={isLoading} 
                    chatHistory={chatHistory} 
                    textareaRef={textareaRef} 
                    inputMessage={inputMessage} 
                    handleInputChange={handleInputChange} 
                    sendMessage={sendMessage}
                    handleKeyPress={handleKeyPress}
                    openBotModal={()=>{
                        setShowBotModal(true)
                        setBotModalType('add')
                    }}
                    changeMode={() => setShowMode('admin')}
                />
                :
                <div style={{width: '100%', height: '100%'}}>
                    <Admin 
                        currentAgent={currentAgent}
                        back={() => setShowMode('chat')}
                    />
                </div>
            }

            {previewImage && (
                <div className="image-preview" onClick={() => setPreviewImage(null)}>
                    <div className="preview-content">
                        <img
                            src={previewImage}
                            alt="Preview"
                            style={{ transform: `scale(${previewScale})` }}
                        />
                        <div className="preview-controls">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                handleZoom(0.1)
                            }}><ZoomIn size={24} /></button>
                            <button onClick={(e) => {
                                e.stopPropagation()
                                handleZoom(-0.1)
                            }}><ZoomOut size={24} /></button>
                        </div>
                        <button className="close-preview" onClick={() => setPreviewImage(null)}>
                            <X size={24} />
                        </button>
                    </div>
                </div>
            )}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            />
            <Dialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDeleteBot}
                title="删除客服警告"
                type='danger'
                content="本次操作会将关联知识库所有数据一同删除, 数据无法恢复且不可逆, 您确定要执行此操作吗？"
            />
            <BotModal
                isOpen={showBotModal}
                fade={closing}
                form={{
                    name: editAgent?.name,
                    avatar: editAgent?.avatar,
                    desc: editAgent?.description,
                }}
                type={botModalType}
                onCancel={handleCloseBotModal}
                onConfirm={botModalType==='add'? handleConfirmAddBot: handleConfirmUpdateBot}
                title={ botModalType==='add'? '添加客服': '编辑客服'}
            />
        </div>
    );
};

export default ChatPage;