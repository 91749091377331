import React, { useState, useEffect } from 'react';
import { InfoIcon, X } from 'lucide-react';
import './dialog.scss';

const Modal = ({ isOpen, onClose, onConfirm, title, content, type='default' }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showType] = useState(type);
  const TypeColors = {
    'success': 'green',
    'warning': 'orange',
    'danger': 'red',
    'info': 'gray',
    'default': 'black'
  }
  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => setIsAnimating(false), 300); // 与 CSS 动画时间匹配
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isAnimating && !isOpen) return null;

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : 'closing'}`}>
      <div className={`modal ${isOpen ? 'open' : 'closing'}`}>
        <button className="close-button" onClick={onClose}>
          <X size={24} />
        </button>
        <div className='modal-title'>
            <InfoIcon className='icon' color={TypeColors[showType]}></InfoIcon>
            <h2 style={{color: TypeColors[showType]}}>{title}</h2>
        </div>
        <div className="modal-content">{content}</div>
        <div className="modal-actions">
          <button className="cancel-button" onClick={onClose}>取消</button>
          <button className="confirm-button" onClick={onConfirm}>确认</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;