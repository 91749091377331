import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/Admin.scss';

const Admin = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState('documents');

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    // 模拟API调用
    const mockDocuments = [
      { id: 10000, name: '小红书运营方法.docx', status: '解析成功', size: '271.15KB', date: '2024-10-09' },
      // 可以添加更多模拟数据...
    ];
    setDocuments(mockDocuments);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleUpload = () => {
    alert('文件上传功能待实现');
  };

  const handleDelete = (id) => {
    alert(`删除文档 ID: ${id}`);
    // 实际应用中，这里应该调用API来删除文档
    setDocuments(documents.filter(doc => doc.id !== id));
  };

  const filteredDocuments = documents.filter(doc =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin">
      <header className="admin-header">
        <h1>管理员控制台</h1>
        <div className="admin-nav">
          <Link to="/admin/profile">管理员信息</Link>
          <Link to="/admin/settings">系统设置</Link>
          <button onClick={() => alert('登出功能待实现')}>登出</button>
        </div>
      </header>
      <main className="admin-main">
        <h2>Test kb_id:KB0e40751e872a4fd4819a6a32049c7d0d_240430</h2>
        <div className="action-bar">
          <div className="tab-group">
            <button 
              className={`tab ${selectedTab === 'documents' ? 'active' : ''}`}
              onClick={() => setSelectedTab('documents')}
            >
              文档集
            </button>
            <button 
              className={`tab ${selectedTab === 'qa' ? 'active' : ''}`}
              onClick={() => setSelectedTab('qa')}
            >
              问答集
            </button>
          </div>
          <div className="search-upload">
            <input
              type="text"
              placeholder="搜索文档"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button onClick={handleUpload} className="upload-btn">上传文档</button>
          </div>
        </div>
        <table className="document-table">
          <thead>
            <tr>
              <th><input type="checkbox" /></th>
              <th>文档ID</th>
              <th>文档名称</th>
              <th>文档状态（解析成功可对话）</th>
              <th>文件大小</th>
              <th>创建日期</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {filteredDocuments.map(doc => (
              <tr key={doc.id}>
                <td><input type="checkbox" /></td>
                <td>{doc.id}</td>
                <td>{doc.name}</td>
                <td>
                  <span className={`status ${doc.status === '解析成功' ? 'success' : 'fail'}`}>
                    {doc.status}
                  </span>
                </td>
                <td>{doc.size}</td>
                <td>{doc.date}</td>
                <td></td>
                <td>
                  <button className="delete-btn" onClick={() => handleDelete(doc.id)}>删除</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
};

export default Admin;