import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import upload from '../../api/upload';

const UploadLine = ({ success }) => {
    const [fileList, setFileList] = useState([]);

    const handleUpload = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const { code, msg, data } = await upload(formData, onProgress);
            if (code !== 2000) throw new Error(msg);
            onSuccess(data, file);
            if (success) success(data, file);
        } catch (error) {
            console.error('Upload error:', error);
            onError({ message: error });
        }
    };

    const handleChange = (info) => {
        console.log('handleChange info:', info);  // 调试日志

        let newFileList = [...info.fileList];

        // 处理文件删除
        if (info.file.status === 'removed') {
            setFileList([]);
            return;
        }

        newFileList = newFileList.slice(-1); // 只保留最新上传的文件

        // 更新文件状态
        newFileList = newFileList.map(file => {
            if (file.response) {
                file.url = file.response.data;
            }
            if (!file.url && file.originFileObj) {
                file.url = URL.createObjectURL(file.originFileObj);
            }
            return file;
        });

        setFileList(newFileList);

        // 只有在文件存在且有状态时才显示消息
        if (newFileList.length > 0 && newFileList[0].status) {
            const file = newFileList[0];
            if (file.status === 'done') {
                message.success(`${file.name} 文件上传成功。`);
            } else if (file.status === 'error') {
                message.error(`${file.name} 文件上传失败。`);
            }
        }
    };

    const uploadProps = {
        customRequest: handleUpload,
        listType: "picture",
        fileList: fileList,
        onChange: handleChange,
        onSuccess: (url, file) => {
            console.log('Upload success:', url, file);
            const fileItem = {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: url,
                thumbUrl: url,
            };
            setFileList([fileItem]);
        },
        onError: (error) => {
            console.error('Upload error:', error);
            const fileItem = {
                uid: fileList[0].uid,
                name: fileList[0].name,
                status: 'error',
            };
            setFileList([fileItem])
        },
    };

    return (
        <div className='item' style={{ marginTop: '10px' }}>
            <Upload {...uploadProps}>
                {fileList.length === 0 && (
                    <Button type="primary" icon={<UploadOutlined />}>
                        头像上传
                    </Button>
                )}
            </Upload>
        </div>
    );
};

export default UploadLine;