import React from 'react';
import { Empty, Button, Space } from 'antd';

const EmptyContentPage = ({ onCreateClick }) => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      width: '100%',
      background: '#f0f2f5'
    }}>
      <Space direction="vertical" align="center">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 60 }}
          description={
            <span>暂无内容，您需要创建一个客服以便获取<br />知识库管理与聊天窗口</span>
          }
        >
          <Button type="primary" onClick={onCreateClick}>
            创建客服
          </Button>
        </Empty>
      </Space>
    </div>
  );
};

export default EmptyContentPage;