import './chat.scss';
import { marked } from 'marked';
import { Settings, Paperclip } from 'lucide-react';
import NoneOne from '../../../components/empty/noneone';
import tool from '../../../utils';

const chat = ({ currentAgent, openImagePreview, chatWindowRef, isLoading, chatHistory, textareaRef, openFileDialog, inputMessage, handleInputChange, sendMessage, changeMode, openBotModal }) => {
   if(!currentAgent) return (
        <NoneOne onCreateClick={openBotModal}>
        </NoneOne>
    )
    return (
        <div className="main-content">
            <div className="chat-header">
                <div className="agent-info">
                    <img
                        src={currentAgent.avatar}
                        alt=''
                        className="current-agent-avatar"
                        onClick={() => openImagePreview(currentAgent.avatar)}
                    />
                    <span className="agent-name">{currentAgent.name}</span>
                </div>
                <div className="header-settings-icon" onClick={changeMode}>
                    <Settings size={20} />
                </div>
            </div>
            <div ref={chatWindowRef} className="chat-window">
                <div>
                    <div className="message ai-message">
                        <div dangerouslySetInnerHTML={{ __html: marked.parse(`您已连接到${currentAgent.name}，可以发送消息啦!`) }} />
                    </div>
                </div>
                {chatHistory?.map((message, index) => (
                    <div key={index}>
                        <div className={`message ${message.sender}-message`}>
                            <div dangerouslySetInnerHTML={{ __html: marked.parse(message.text) }} />
                            <div className="time" style={{color: message.sender === 'ai' ? '#ccc' : '#c4c4c4', fontSize: '12px', marginTop: '5px'}}>
                                {tool.formatRelativeTime(message.time)}
                            </div>
                        </div>
                    </div>
                ))}
                {isLoading && (
                    <div className="message ai-message loading-message">
                        <div className="loading-indicator">
                            <div className="loading-spinner"></div>
                            <p>AI正在思考...</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="input-area">
                <textarea
                    ref={textareaRef}
                    value={inputMessage}
                    onChange={handleInputChange}
                    onKeyPress={(e) => {
                        e.key === 'Enter' && !e.shiftKey && sendMessage();
                    }}
                    placeholder="输入您的消息..."
                    id="message-input"
                />
                <button className="button" onClick={sendMessage}>发送</button>
            </div>
        </div>
    )
}

export default chat