import React, { useState, useCallback, useEffect } from 'react';
import { Input, Modal, Space, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import './upload.scss';
import upload from '../../../api/upload';

const { Dragger } = Upload;

const FileUploadModal = ({ isOpen, onClose, onConfirm }) => {
    const [fileList, setFileList] = useState([]);
    const [remark, setRemark] = useState('');
    const handleUpload = useCallback(async ({ file, onSuccess, onError, onProgress }) => {
        const formData = new FormData();
        formData.append('file', file);

        const { code, msg, data } = await upload(formData, onProgress);
        if (code !== 2000) return message.error(msg);
        // 这里模拟文件上传过程
        setTimeout(() => {
            if (file.size > 100 * 1024 * 1024) { // 100MB
                onError(new Error('File size should not exceed 100MB'));
                message.error('文件大小应不超过 100MB');
            } else {
                onSuccess(data, file);
                message.success(`${file.name} 文件已成功上传`);
            }
        }, 1000);
    }, []);

    const handleChange = useCallback(({ fileList }) => setFileList(fileList), []);

    const handleConfirm = useCallback(() => {
        onConfirm(fileList, remark);
    }, [fileList, onConfirm]);

    const uploadProps = {
        name: 'file',
        multiple: true,
        fileList,
        customRequest: handleUpload,
        onChange: handleChange,
        onSuccess: (url, file) => {
            const fileItem = {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url,
                blob: file,
                size: file.size,
                thumbUrl: url,
            };
            setFileList([fileItem]);
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Modal
            title="上传文档"
            open={isOpen}
            onOk={handleConfirm}
            onCancel={onClose}
            okText="确定"
            cancelText="取消"
            className="file-upload-modal"
        >
            <div style={{marginBottom: '15px'}}>
                <Input placeholder='备注说明一下' value={remark} onChange={e=>setRemark(e.target.value)}></Input>
            </div>
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                <p className="ant-upload-hint">
                    支持单个或批量上传。严禁上传公司数据或其他敏感文件。
                </p>
                <p className="file-info">
                    支持文件格式md, txt, pdf, jpg, docx, xlsx, pptx, eml, csv, jsonl, 单问可上传文件个数&lt;1万个, 单个文档&lt;100M, 单个文件字数&lt;500万字, 图片&lt;5M
                </p>
            </Dragger>
        </Modal>
    );
};

export default FileUploadModal;