import React, { useState } from 'react';
import { Table, Typography, Tooltip, Modal, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const AdminTable = ({ data, selectedTab, selectedItems, onSelectItem, onSelectAll, onDelete, openModal }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const showModal = (title, content) => {
    setModalContent({ title, content });
    setModalVisible(true);
  };

  const documentColumns = [
    {
      title: <input
        type="checkbox"
        onChange={(e) => onSelectAll(e.target.checked)}
        checked={selectedItems.length === data.length && data.length > 0}
      />,
      dataIndex: 'selection',
      width: 50,
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedItems.includes(record.id)}
          onChange={() => onSelectItem(record.id)}
        />
      ),
    },
    { title: '文档ID', dataIndex: 'id', key: 'id', width: 80 },
    {
      title: '文档名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <Tooltip title={text}>
          <a href={record.path} target="_blank" rel="noopener noreferrer">{text}</a>
        </Tooltip>
      ),
    },
    {
      title: '文档状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) => (
        <span className={`status ${status === 0 ? 'success' : 'fail'}`}>
          {status === 0 ? '成功解析' : '解析失败'}
        </span>
      ),
    },
    { title: '文件大小', dataIndex: 'size', key: 'size', width: 100 },
    { title: '创建日期', dataIndex: 'date', key: 'date', width: 120 },
    { title: '备注', dataIndex: 'remark', key: 'remark', width: 150, ellipsis: true },
    {
      title: '操作',
      key: 'action',
      width: 80,
      render: (_, record) => (
        <button className="delete-btn" onClick={() => onDelete(record.id)}>删除</button>
      ),
    },
  ];

  const qaColumns = [
    {
      title: <input
        type="checkbox"
        onChange={(e) => onSelectAll(e.target.checked)}
        checked={selectedItems.length === data.length && data.length > 0}
      />,
      dataIndex: 'selection',
      width: 50,
      render: (_, record) => (
        <input
          type="checkbox"
          checked={selectedItems.includes(record.id)}
          onChange={() => onSelectItem(record.id)}
        />
      ),
    },
    { title: '问题ID', dataIndex: 'id', key: 'id', width: 80 },
    {
      title: '问题',
      dataIndex: 'question',
      key: 'question',
      width: 300,
      render: (text) => (
        <Tooltip title={<EyeOutlined onClick={() => showModal('问题', text)} />}>
          <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '...' }} style={{ marginBottom: 0 }}>
            {text}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: '答案',
      dataIndex: 'answer',
      key: 'answer',
      width: 300,
      render: (text) => (
        <Tooltip title={<EyeOutlined onClick={() => showModal('答案', text)} />}>
          <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '...' }} style={{ marginBottom: 0 }}>
            {text}
          </Paragraph>
        </Tooltip>
      ),
    },
    { title: '创建日期', dataIndex: 'date', key: 'date', width: 120 },
    {
      title: '操作',
      key: 'action',
      width: 80,
      render: (_, record) => (
        <button className="delete-btn" onClick={() => onDelete(record.id)}>删除</button>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={selectedTab === 'documents' ? documentColumns : qaColumns}
        dataSource={data}
        rowKey="id"
        scroll={{ x: 'max-content' }}
        locale={{
          emptyText: (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Typography.Text>
                  暂无{selectedTab === 'documents' ? '文档' : '问答'}内容
                  <Tag style={{margin: '5px', cursor: 'pointer'}} onClick={openModal}>立即上传</Tag>
                </Typography.Text>
            </div>
          ),
        }}
      />
      <Modal
        title={modalContent.title}
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>{modalContent.content}</p>
      </Modal>
    </>
  );
};

export default AdminTable;