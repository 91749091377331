import React, { useState } from 'react';
import './qamodal.scss';
import { X } from 'lucide-react';
import { message } from 'antd';

const QaModal = ({ onClose, onConfirm }) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [hiding, setHiding] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (question.trim() === '') return message.error('请输入问题');
        if (answer.trim() === '') return message.error('请输入回答');
        onConfirm({ question, answer });
        setQuestion('');
        setAnswer('');
    };

    const handleClose = () => {
        setHiding(true)
        setTimeout(() => {
            setHiding(false)
            onClose()
        }, 500);
    }

    return (
        <div className="qa-modal" onClick={handleClose}>
            <div className={hiding ? `qa-modal-content hiding` : `qa-modal-content`} onClick={e => e.stopPropagation()}>
                <div className='close' onClick={handleClose}>
                    <X></X>
                </div>
                <h2>增加新的问答</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="question">问题：</label>
                        <input
                            id="question"
                            type="text"
                            value={question}
                            placeholder='请输入问题...'
                            onChange={(e) => setQuestion(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="answer">答案：</label>
                        <textarea
                            id="answer"
                            value={answer}
                            placeholder='请输入答案...'
                            onChange={(e) => setAnswer(e.target.value)}
                            required
                        />
                    </div>
                    <div className="qa-modal-actions">
                        <button type="button" onClick={handleClose}>取消</button>
                        <button type="submit">确定</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QaModal;