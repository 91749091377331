import axios from 'axios';

export const API_BASE_URL = 'https://www.hspace.top/qa-system/api/upload';

export const upload = (fileForm, onProgress=()=>false) => {
    const client = axios.create({
        baseURL: API_BASE_URL,
        method: 'POST',
        data: fileForm,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress({ percent });
        },
    });
    
    // 响应拦截器
    client.interceptors.response.use(
        (response) => response.data,
        (error) => {
            // 你可以在这里添加全局错误处理逻辑
            console.error('API call failed:', error);
            return Promise.reject(error);
        }
    );
    return client.post(API_BASE_URL, fileForm)
};



export default upload;