import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './admin.scss';
import FileUploadModal from '../../../components/modals/upload/upload';
import QaModal from '../../../components/modals/qamodal/qamodal';
import api from '../../../api';
import { Empty, message, Typography } from 'antd';
import Dialog from '../../../components/modals/dialog/dialog'
import AdminTable from '../../../components/AdminTable';

const Admin = ({ back, currentAgent }) => {
    const [documents, setDocuments] = useState([]);
    const [qaItems, setQaItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTab, setSelectedTab] = useState('documents');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedQaItems, setSelectedQaItems] = useState([]);
    const [isQaModalOpen, setIsQaModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [showBatchDialog, setShowBatchDialog] = useState(false)
    const documentPageInfo = {
        current: 1,
        pageSize: 10,
        total: 0
    }
    const qaPageInfo = {
        current: 1,
        pageSize: 10,
        total: 0
    }

    useEffect(() => {
        setSelectedDocuments([])
        setSelectedQaItems([])
        fetchDocuments();
        fetchQaItems();
    }, [currentAgent]);

    const fetchDocuments = async () => {
        const { current, pageSize } = documentPageInfo
        const { code, msg, data } = await api.post('/bot/get_documents', {current, pageSize, agentId: currentAgent.id})
        if(code!==2000) return message.error(msg);
        const {list, total} = data
        documentPageInfo.total = total
        const mockDocuments = [
            //   { id: 10000, name: '小红书运营方法.docx', status: '解析成功', size: '271.15KB', date: '2024-10-09' },
            //   { id: 10001, name: '产品策划书.pdf', status: '解析中', size: '1.5MB', date: '2024-10-10' },
            //   { id: 10002, name: '市场调研报告.xlsx', status: '解析成功', size: '500KB', date: '2024-10-11' },
        ];
        const documents = list.map(item=>({
            id: item.id,
            name: item.name,
            status: item.status,
            size: item.size,
            path: item.path,
            date: item.createTime    
        }))
        setDocuments([...mockDocuments, ...documents]);
    };

    const fetchQaItems = async () => {
        const { current, pageSize } = qaPageInfo
        const { code, data, msg } = await api.post('/bot/get_qa_list', {current, pageSize, agentId: currentAgent.id})
        if(code!==2000) return message.error(msg);
        const { list, total } = data
        const mockQaItems = [
            //   { id: 1, question: '如何提高用户留存率？', answer: '通过优化产品体验、提供个性化服务和定期推送有价值的内容来提高用户留存率。', date: '2024-10-09' },
            //   { id: 2, question: '什么是A/B测试？', answer: 'A/B测试是一种比较两个版本的网页或应用以确定哪个版本表现更好的方法。', date: '2024-10-10' },
            //   { id: 3, question: '如何进行市场细分？', answer: '市场细分可以通过人口统计、地理位置、行为和心理特征等因素来进行。', date: '2024-10-11' },
        ];
        qaPageInfo.total = total
        const qas = list.map(item=>({
            id: item.id,
            question: item.question,
            answer: item.answer,
            size: item.size,
            date: item.createTime
        }))
        setQaItems([...mockQaItems, ...qas]);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleUpModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleConfirmFileUpload = async (fileList, remark) => {
        const file = fileList[0];
        console.log('File upload confirmed', file);
        if(!file?.size) return message.error('空文件上传');
        const params = {
            agentId: currentAgent.id, 
            name: file.name, 
            path: file.url, 
            blob: file.file,
            size: file.size, 
            status: file.status,
            remark
        }
        const { code, msg, data } = await api.post('/bot/add_document', {...params})
        if(code!==2000) return message.error(msg);
        message.success('文件上传成功');
        fetchDocuments();
        setIsModalOpen(false);
    };

    const handleDelete = async (id) => {
        if (selectedTab === 'documents') {
            const { code, msg } = await api.delete('/bot/delete_document?uid=' + id)
            if(code!==2000) return message.error(msg);
            message.success('文档已删除');
            setDocuments(documents.filter(doc => doc.id !== id));
        } else {
            const { code, msg } = await api.delete('/bot/delete_qa?uid=' + id)
            if(code!==2000) return message.error(msg);
            message.success('问答已删除');
            setQaItems(qaItems.filter(item => item.id !== id));
        }
        setDeleteId(null)
    };

    const handleBatchDelete = async () => {
        console.log(selectedDocuments);
        if (selectedTab === 'documents') {
            const { code, msg } = await api.post('/bot/delete_documents', {agentId: currentAgent.id, ids: selectedDocuments})
            if(code!==2000) return message.error(msg);
            setDocuments(documents.filter(doc => !selectedDocuments.includes(doc.id)));
            setSelectedDocuments([]);
        } else {
            const { code, msg } = await api.post('/bot/delete_qas', {agentId: currentAgent.id, ids: selectedQaItems})
            if(code!==2000) return message.error(msg);
            setQaItems(qaItems.filter(item => !selectedQaItems.includes(item.id)));
            setSelectedQaItems([]);
        }
        setShowBatchDialog(false)
    };

    const handleSelectAll = (isSelected) => {
        if (selectedTab === 'documents') {
          setSelectedDocuments(isSelected ? documents.map(doc => doc.id) : []);
        } else {
          setSelectedQaItems(isSelected ? qaItems.map(item => item.id) : []);
        }
      };

    const handleSelectItem = (id) => {
        if (selectedTab === 'documents') {
            setSelectedDocuments(prev =>
                prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
            );
        } else {
            setSelectedQaItems(prev =>
                prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
            );
        }
    };

    const handleAddQa = () => {
        setIsQaModalOpen(true);
    };

    const handleCloseQaModal = () => {
        setIsQaModalOpen(false);
    };

    const handleConfirmAddQa = async (newQa) => {
        const { code, msg, data } = await api.post('/bot/add_qa', { agentId: currentAgent.id, question: newQa.question, answer: newQa.answer })
        if (code !== 2000) return message.error(msg);
        message.success(msg)
        // 这里添加新的问答到 qaItems
        setQaItems([...qaItems, { ...newQa, id: data.id, date: data.createTime }]);
        setIsQaModalOpen(false);
    };

    const filteredDocuments = documents.filter(doc =>
        doc.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredQaItems = qaItems.filter(item =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="admin">
            <header className="admin-header">
                <h1>{currentAgent?.name} - 知识库管理</h1>
                <div className="admin-nav">
                    <button onClick={back}>关闭</button>
                </div>
            </header>
            <main className="admin-main">
                <h2>{currentAgent.name} kb_id: {currentAgent.kb_id}</h2>
                <div className="action-bar">
                    <div className="tab-group">
                        <button
                            className={`tab ${selectedTab === 'documents' ? 'active' : ''}`}
                            onClick={() => setSelectedTab('documents')}
                        >
                            文档集
                        </button>
                        <button
                            className={`tab ${selectedTab === 'qa' ? 'active' : ''}`}
                            onClick={() => setSelectedTab('qa')}
                        >
                            问答集
                        </button>
                    </div>
                    <div className="search-upload">
                        <input
                            type="text"
                            placeholder={selectedTab === 'documents' ? "搜索文档" : "搜索问答"}
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        {selectedTab === 'documents' ? (
                            <button onClick={handleUpModal} className="upload-btn">上传文档</button>
                        ) : (
                            <button onClick={handleAddQa} className="upload-btn">增加问答</button>
                        )}
                    </div>
                </div>
                <AdminTable 
                    data={selectedTab === 'documents' ? filteredDocuments : filteredQaItems}
                    selectedTab={selectedTab}
                    selectedItems={selectedTab === 'documents' ? selectedDocuments : selectedQaItems}
                    onSelectItem={handleSelectItem}
                    onSelectAll={handleSelectAll}
                    onDelete={(id) => setDeleteId(id)}
                    openModal={selectedTab === 'documents' ? handleUpModal : handleAddQa}
                    />
                {(selectedDocuments.length > 0 || selectedQaItems.length > 0) && (
                    <div className="batch-actions">
                        <button onClick={()=>setShowBatchDialog(true)}>批量删除</button>
                    </div>
                )}
            </main>
            <FileUploadModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmFileUpload}
            />
            {isQaModalOpen && (
                <QaModal
                    onClose={handleCloseQaModal}
                    onConfirm={handleConfirmAddQa}
                />
            )}
            {
                <Dialog
                    isOpen={deleteId}
                    onClose={()=>setDeleteId(null)}
                    onConfirm={()=>handleDelete(deleteId)}
                    title={selectedTab === 'documents'?'文档删除提示':'问答删除提示'}
                    content={`删除之后数据将无法恢复，确认删除 ID为${deleteId} 的${selectedTab === 'documents'? '文档': '问答'}吗？`}
                    type='default'
                />
            }
            {
                <Dialog 
                    isOpen={showBatchDialog}
                    onClose={()=>setShowBatchDialog(false)}
                    onConfirm={handleBatchDelete}
                    title='批量删除提示'
                    content={`你确定要删除所有选中的${selectedTab === 'documents'?'文档数据':'问答数据'}吗？`}
                    type='default'
                />
            }
        </div>
    );
};

export default Admin;