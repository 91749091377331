import { message } from 'antd';
import './botmodal.scss'
import UploadLine from '../../uploads/line'
import { useEffect, useState } from 'react';

function BotModal ({title, onConfirm, onCancel, isOpen, fade, form, type}) {
    const [name, setName] = useState(form?.name ||'')
    const [desc, setDesc] = useState(form?.desc ||'')
    const [avatar, setAvatar] = useState(form?.avatar || '')
    
    useEffect(()=>{
        if(form) {
            setName(type==='update'?form.name: '')
            setDesc(type==='update'?form.desc:'')
            setAvatar(type==='update'?form.avatar:'')
        }
    }, [form, type])
    
    if(!isOpen) return null

    const handleConfirm = () => {
        if(name.trim() === '') {
            message.error('请输入客服名称');
            return;
        }
        if(desc.trim() === '' && type!=='update') {
            message.error('请输入描述说明');
            return;
        }
        if(avatar === '' && type!=='update') {
            message.error('请上传头像');
            return;
        }
        const params = {
            avatar,
            name: name.trim(),
            desc: desc.trim()
        }
        onConfirm(params);
        setAvatar('')
        setName('')
        setDesc('')
    }

    const handleAvatarUpload = (url, file) => {
        setAvatar(url);
    }

    return (
        <div className="bot-modal" onClick={onCancel}>
            <div className={fade ? `panel fade` : 'panel'} onClick={e => e.stopPropagation()}>
                <div className="close" onClick={onCancel}>
                    X
                </div>
                <div className="title">{title}</div>
                <div className="body">
                    <div className='form'>
                        <div className='input-box'>
                            <label className='label'>客服名称</label>
                            <input 
                                placeholder='请输入客服名称' 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='input-box'>
                            <label className='label'>描述说明</label>
                            <textarea 
                                placeholder='请输入描述'
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </div>
                        <div className='avatar-box'>
                            <UploadLine success={handleAvatarUpload}/>
                        </div>
                        <div className='action-box'>
                            <button className='cancel' onClick={onCancel}>取消</button>
                            <button className='confirm' onClick={handleConfirm}>确认</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BotModal;