export function convertChatToQA(chatArray) {
    const qaArray = [];
    let currentQuestion = null;
  
    for (let i = 0; i < chatArray.length; i++) {
      const message = chatArray[i];
  
      if (message.sender === 'user') {
        // 如果遇到新的用户消息，保存为当前问题
        currentQuestion = message.text;
      } else if (message.sender === 'ai' && currentQuestion) {
        // 如果是AI回复，且存在当前问题，并且回复不为空
        if (message.text && message.text.trim() !== '') {
          qaArray.push({
            question: currentQuestion,
            response: message.text
          });
          currentQuestion = null; // 重置当前问题
        }
      }
    }
  
    return qaArray;
  }

  export function formatRelativeTime(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);
  
    if (diffInSeconds < 60) {
      return '刚刚';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}分钟前`;
    } else if (diffInHours < 24) {
      return `${diffInHours}小时前`;
    } else if (diffInDays < 30) {
      return `${diffInDays}天前`;
    } else if (diffInMonths < 12) {
      return diffInMonths === 1 ? '1个月前' : `${diffInMonths}个月前`;
    } else {
      return diffInYears === 1 ? '1年前' : `${diffInYears}年前`;
    }
  }
  
  const tool = {
    formatRelativeTime,
    convertChatToQA
  }

  export default tool;